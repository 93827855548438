<template>
  <Layout>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        <!-- Details Added Successfully! -->
        {{alertMsg}}
      </b-alert>
    </div>
    <PageHeader :items="items" :title="title" />

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-tabs content-class="pt-0 px-2 text-muted" nav-class="p-0" nav-wrapper-class="col-sm-3" pills vertical>

            <!-- add Speciality tab start here -->
            <b-tab active title="Add Speciality" title-item-class="mb-2">
              <b-card-text>
                <h6> Add Speciality</h6>
                <div class="row">
                  <div class="mt-3 col-md-4">
                    <label> Speciality Name</label>
                    <input v-model="specialityName" class="form-control" placeholder="Eg:Chinese" type="text">
                  </div>
                  <div class=" col-md-2" style="margin-top:40px;">
                    <b-spinner v-if="loading" class="m-2 col-3" role="status" variant="primary"></b-spinner>
                    <button :disabled="loading" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" type="button" @click.prevent="submitForm(1,0);">Submit</button>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <!-- add Speciality tab end here -->

            <!-- add Speciality table start here -->
            <b-tab title="View Specialities" title-item-class="mb-2" @click="getSpecialities();">
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-4" style="margin-bottom:10px;">
                    <button class="btn btn-themeBrown"
                            type="button"
                            @click="downloadSample(1)"
                    >
                      Excel
                    </button>
                  </div>
                  <!-- <div class="col-sm-12 col-md-4" style="margin-bottom:10px;">
                      <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                              Show &nbsp;&nbsp;
                              <b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;&nbsp;&nbsp; entries
                          </label>
                      </div>
                  </div> -->
                  <div class="col-sm-12 col-md-4">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show &nbsp;&nbsp;
                        <b-form-select v-model="perPage"
                                       :options="pageOptions"
                                       size="sm"
                                       style="margin-left:5px; margin-right:5px"
                        ></b-form-select
                        >&nbsp;&nbsp;&nbsp; entries
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="col-sm-12 col-md-4 row" style="margin-bottom:10px;">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" class="form-control form-control-sm ms-2" placeholder="Search..." type="search"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <!-- Table speciality -->
                <div class="table-responsive mb-0">
                  <b-table :current-page="currentPage" :fields="fields" :filter="filter" :filter-included-fields="filterOn" :items="tableData" :per-page="perPage" fixed-header hover responsive="sm" sticky-header="500px" @filtered="onFiltered">
                    <!-- <template #cell(Action)>
                        <i @click="sectionModal1=!sectionModal1" class="uil uil-edit-alt" title="Edit" style="font-size: 19px;color:#f3766a;"></i>
                    </template> -->
                    <template v-slot:cell(Action)="data">
                      <!-- <router-link :to="{  params: { id: tableData.cuisineID , type:'edit' } }"> -->
                      <!-- <i class="uil uil-edit-alt" title="Edit"  style="font-size: 19px;"></i></router-link> -->
                      <i v-if="editRight == 1" class="uil uil-edit-alt" style="font-size: 19px;cursor:pointer;color:#F3766A;" @click="openSecalityModal(data.item)"></i>
                      <!-- </router-link> -->
                      <!-- <button @click="openModal(data.item.principleID)"><i class="uil-user-plus"></i></button> -->
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                        class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="rows"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <!-- Table speciality end here-->

            <!-- modal for speciality edit  -->
            <b-modal id="modal-lg-editspecility" v-model="sectionModal1" title="Edit Speciality" @ok="submitForm(1,1)">
              <div class="col-md-12 mb-2">
                <!-- <h6> Add Speciality</h6> -->
                <div class="col-md-6">
                  <label> Speciality Name</label>
                  <input v-model="cuisineData.cuisineName" class="form-control" placeholder="Eg:Chinese" type="text">
                </div>
                <div class=" row" style="float:right;">
                  <b-spinner v-if="loading" class="m-2 col-3" role="status" variant="primary"></b-spinner>
                  <!-- <button type="button" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading" @click.prevent="submitForm(1);">Submit</button> -->
                </div>
              </div>
            </b-modal>
            <!-- modal for speciality end  -->

            <!-- Location add start here -->
            <b-tab title="Add Location" title-item-class="mb-2">
              <b-card-text>

                <h6> Add Location</h6>
                <div class="row">
                  <div class="mt-3 col-md-6">
                    <label> Location Name*</label>
                    <input v-model="branchName" class="form-control" placeholder="Eg:Malad West" type="text">
                  </div>
                  <div class="mt-3 col-md-6">
                    <label> City</label>
                    <input v-model="city" class="form-control" placeholder="Eg:Mumbai" type="text">
                  </div>
                </div>

                <div class="row">
                  <!-- <div class="mt-3 col-md-6">
                      <label> State</label>
                      <input class="form-control" placeholder="Eg:Maharashtra"  type="text" v-model="state">
                  </div> -->
                  <div class="mt-3 col-md-6">
                    <label>Select State</label>
                    <multiselect v-model="state" :options="statesTableData" label="state" track-by="stateID"  ></multiselect>
                  </div>
                </div>
                <div class=" row" style="float:right;">
                  <b-spinner v-if="loading" class="m-2 col-3" role="status" variant="primary"></b-spinner>
                  <button :disabled="loading" class="mt-3 col-3 btn btn-themeYellow w-md waves-effect waves-light" type="button" @click.prevent="submitForm(2,0);">Submit</button>
                </div>

              </b-card-text>
            </b-tab>
            <!-- Location add end here -->

            <b-tab title="View Locations" title-item-class="mb-2" @click="getLocations();">
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-4" style="margin-bottom:10px;">
                    <button class="btn btn-themeBrown"
                            type="button"
                            @click="downloadSample(2)"
                    >
                      Excel
                    </button>
                  </div>
                  <div class="col-sm-12 col-md-4" style="margin-bottom:10px;">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show &nbsp;&nbsp;
                        <b-form-select v-model="perPage2" :options="pageOptions2" size="sm" style="margin-left:5px; margin-right:5px"></b-form-select>&nbsp;&nbsp;&nbsp; entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-4 row" style="margin-bottom:10px;">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter2" class="form-control form-control-sm ms-2" placeholder="Search..." type="search"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->

                  <!-- Table Locations start -->
                  <div class="table-responsive mb-0">
                    <b-table :current-page="currentPage2" :fields="fields2" :filter="filter2" :filter-included-fields="filterOn2" :items="locationTableData" :per-page="perPage2" fixed-header hover responsive="sm" sticky-header="500px" @filtered="onFiltered2">
                      <template v-slot:cell(Action)="data">
                        <i v-if="editRight == 1" class="uil uil-edit-alt" style="font-size: 19px;cursor:pointer;color:#F3766A;" @click="openLocationModal(data.item)"></i>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage2" :per-page="perPage2" :total-rows="rows2"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <!-- Table Locations  end-->
            <!-- modal for Location edit  -->
            <b-modal id="modal-lg-editlocation" v-model="sectionModal2" title="Edit Location" @ok="submitForm(2,1)">
              <div class="row">
                <div class=" col-md-6">
                  <label> Location Name*</label>
                  <input v-model="locationData.branchName" class="form-control" placeholder="Eg:Malad West" type="text">
                </div>
                <div class=" col-md-6">
                  <label> City</label>
                  <input v-model="locationData.city" class="form-control" placeholder="Eg:Mumbai" type="text">
                </div>
              </div>

              <div class="row">

                <div class="mt-3 col-md-6">
                  <label> State</label>

                  <multiselect v-model="locationData.stateOb" :options="statesTableData" label="state" track-by="stateID"  ></multiselect>
                </div>
                <!-- {{locationData.state}} -->
              </div>
            </b-modal>
            <!-- modal for Location edit end here -->

            <!-- State add start here -->
            <!-- <b-tab title="Add State" title-item-class="mb-2">
                <b-card-text> -->
            <b-modal id="modal-lg-addstates" v-model="sectionModal6" title="Add State" >

              <div class="row">
                <div class=" col-md-6">
                  <label> State</label>
                  <input v-model="state" class="form-control" placeholder="Eg.Maharashtra" type="text">

                </div>
                <div class=" col-md-6">
                  <label> Country</label>
                  <input v-model="country" class="form-control" type="text">
                </div>
              </div>
              <div class="row">
                <div class="mt-3 col-md-6">
                  <label>Short Code</label>
                  <input v-model="shortCode" class="form-control" maxlength="2" placeholder="Enter Short Code" type="text">
                </div>
                <div class="mt-3 col-md-6">
                  <label> CloudKitch GST No.</label>
                  <input v-model="cloudKitchGstNo" class="form-control" placeholder="Enter Gst No." type="text">
                </div>
              </div>
              <div class="row">

                <div class="mt-3 col-md-12">
                  <label> CloudKitch Billing Address</label>
                  <textarea v-model="cloudKitchBillingAddress" class="form-control" placeholder="Enter Billing Address" type="text">
                                   </textarea>
                </div>
              </div>
              <template #modal-footer style="border-top:none !important;">
                <div class="w-100">
                  <button class=" col-3 btn btn-themeYellow w-md waves-effect waves-light" style="float:right;" type="button" variant="primary" @click.prevent="addStates(0);">
                    Add
                  </button>
                </div>
              </template>
            </b-modal>
            <!-- </b-card-text>
            </b-tab> -->
            <!-- State add end here -->

            <!-- State Table start here -->
            <b-tab title="View States" title-item-class="mb-2" @click="getStates();">
              <b-card-text>

                <div class="card-body">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                      <div class="btn-group" role="group">
                        <button class="btn btn-themeBrown"
                                type="button"
                                @click="downloadSample(3)"
                        >
                          Excel
                        </button>
                        <button class="btn btn-themeBrown" type="button" @click="openStatesAddModal()">Add State</button>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Show &nbsp;&nbsp;
                          <b-form-select v-model="perPage5" :options="pageOptions5" size="sm" style="margin-left:5px; margin-right:5px"></b-form-select>&nbsp;&nbsp;&nbsp; entries
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="col-sm-12 col-md-4 row">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input v-model="filter5" class="form-control form-control-sm ms-2" placeholder="Search..." type="search"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table :current-page="currentPage5" :fields="fields5" :filter="filter5" :filter-included-fields="filterOn5" :items="statesTableData" :per-page="perPage5" fixed-header hover responsive="sm" sticky-header="500px" @filtered="onFiltered5">
                      <template v-slot:cell(Action)="data">
                        <i v-if="editRight == 1" class="uil uil-edit-alt" style="font-size: 19px;cursor:pointer;color:#F3766A;" @click="openStatesModal(data.item)"></i>
                      </template>
                      <template v-slot:cell(cloudKitchBillingAddress)="data">
                        <span :title="data.item.cloudKitchBillingAddress">{{data.item.cloudKitchBillingAddress}}</span>
                      </template>
                    </b-table>

                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage5" :per-page="perPage5" :total-rows="rows5"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </b-card-text>
            </b-tab>
            <!-- State Table end here -->

            <!-- modal for states edit  -->
            <b-modal id="modal-lg-editstates" v-model="sectionModal5" title="Edit State" >
              <!-- <div class="row"> -->
              <div class="row ">
                <div class=" col-md-6">
                  <label> State</label>
                  <input v-model="statesData.state" class="form-control" type="text">

                </div>
                <div class=" col-md-6">
                  <label> Country</label>
                  <input v-model="statesData.country" class="form-control" type="text">
                </div>
              </div>
              <div class="row">
                <div class="mt-3 col-md-6">
                  <label>Short Code</label>
                  <input v-model="statesData.shortCode" class="form-control" disabled maxlength="2" placeholder="Enter Short Code" type="text">
                </div>
                <div class="mt-3 col-md-6">
                  <label> CloudKitch GST No.</label>
                  <input v-model="statesData.cloudKitchGstNo" class="form-control" placeholder="Enter Gst No." type="text">
                </div>
              </div>
              <div class="row">

                <div class="mt-3 col-md-12">
                  <label> CloudKitch Billing Address</label>
                  <textarea v-model="statesData.cloudKitchBillingAddress" class="form-control" placeholder="Enter Billing Address" type="text">
                                   </textarea>
                </div>
              </div>

              <template #modal-footer style="border-top:none !important;">
                <div class="w-100">
                  <button class=" col-3 btn btn-themeYellow w-md waves-effect waves-light" size="sm" style="float:right;" type="button" variant="primary" @click="addStates(1)">
                    Save
                  </button>
                </div>
              </template>
            </b-modal>
            <!-- modal for states edit end here -->

            <!-- Category add start here -->
            <b-tab title="Add Category" title-item-class="mb-2">
              <b-card-text>

                <h6> Add Category</h6>
                <div class="row">
                  <div class="mt-3 col-md-4">
                    <label class="form-label" for="formrow-categoryName-input">Category Name *</label>
                    <input id="formrow-categoryName-input" v-model="categoryName" class="form-control" name="categoryName" type="text">
                  </div>
                  <div class="col-md-2" style="margin-top:22px;">
                    <b-spinner v-if="loading" class="m-2 col-3" role="status" variant="primary"></b-spinner>
                    <button :disabled="loading" class="mt-3 col-3 btn btn-themeYellow w-md waves-effect waves-light" type="button" @click.prevent="submitForm(3,0);">Submit</button>
                  </div>
                </div>

              </b-card-text>
              <!-- Category add end here -->
            </b-tab>

            <b-tab title="View Categories" title-item-class="mb-2" @click="getCategories();">
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <button class="btn btn-themeBrown"
                            type="button"
                            @click="downloadSample(4)"
                    >
                      Excel
                    </button>
                  </div>
                  <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show &nbsp;&nbsp;
                        <b-form-select v-model="perPage3" :options="pageOptions3" size="sm" style="margin-left:5px; margin-right:5px"></b-form-select>&nbsp;&nbsp;&nbsp; entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-4 row" style="margin-bottom:15px;">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter3" class="form-control form-control-sm ms-2" placeholder="Search..." type="search"></b-form-input>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- End search -->

                <!-- Table Category -->
                <div class="table-responsive mb-0">
                  <b-table :current-page="currentPage3" :fields="fields3" :filter="filter3" :filter-included-fields="filterOn3" :items="categoryDatatable" :per-page="perPage3" fixed-header hover responsive="sm" sticky-header="500px" @filtered="onFiltered3">
                    <template #cell(Action)="data">
                      <i v-if="editRight == 1" class="uil uil-edit-alt" style="font-size: 19px;cursor:pointer;color:#F3766A;" @click="openCategoryModal(data.item)"></i>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage3" :per-page="perPage3" :total-rows="rows3"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <!-- Table Category end-->
            <!-- modal for Category edit  -->
            <b-modal id="modal-lg-editcategory" v-model="sectionModal3" title="Edit Category" @ok="submitForm(3,1)">
              <div class="mb-3 col-md-6">
                <label class="form-label" for="formrow-categoryName-input">Category Name *</label>
                <input id="formrow-categoryName-input" v-model="categoryData.categoryName" class="form-control" name="categoryName" type="text">
              </div>
            </b-modal>
            <!-- modal for Category edit end here -->
            <!-- Corporate Holidays -->

            <!-- Category add start here -->
            <b-tab title="Add Corporate Holidays" title-item-class="mb-2">
              <b-card-text>

                <h6> Add Corporate Holidays</h6>
                <div class="row">
                  <div class="mt-3 col-md-6">
                    <label>Select Corporate*</label>
                    <multiselect v-model="corporateID" :options="corporates" label="corporateName" track-by="corporateID" @input="getCorporateList();"></multiselect>
                  </div>
                  <div class="mt-5 col-md-6">
                    <button v-b-modal.addHolidayModal class="btn btn-themeYellow"> Add Holiday <i class="uil uil-plus"></i></button>
                  </div>

                </div>

              </b-card-text>
              <!-- Category add end here -->
            </b-tab>

            <b-tab title="View Corporate Holidays" title-item-class="mb-2" @click="getHolidaysData();">
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <button class="btn btn-themeBrown"
                            type="button"
                            @click="downloadSample(5)"
                    >
                      Excel
                    </button>
                  </div>
                  <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show &nbsp;&nbsp;
                        <b-form-select v-model="perPage4" :options="pageOptions4" size="sm" style="margin-left:5px; margin-right:5px"></b-form-select>&nbsp;&nbsp;&nbsp; entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-4 row" style="margin-bottom:15px;">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter4" class="form-control form-control-sm ms-2" placeholder="Search..." type="search"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                  <div class="row">
                    <div class="mt-3 col-md-6" st>
                      <label>Select Corporate*</label>
                      <multiselect v-model="corporateID" :options="corporates" label="corporateName" track-by="corporateID"></multiselect>
                    </div>
                    <!-- Apply filter -->
                    <div class="col-md-2" style="width:auto;margin-top:15px;">
                      <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()"> Apply Filter </button>
                    </div>
                    <!-- Clear filter -->
                    <div class="col-md-2" style="width:16%;margin-top:15px;">
                      <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()"> Clear Filter </button>
                    </div>
                  </div>
                  <!-- Table holiday  -->

                  <div class="table-responsive mb-0">
                    <b-table :current-page="currentPage4" :fields="fields4" :filter="filter4" :filter-included-fields="filterOn4" :items="tableData4" :per-page="perPage4" fixed-header hover responsive="sm" sticky-header="500px" @filtered="onFiltered4">
                      <template #cell(Action)="data">
                        <i v-if='data.item.isExpired == 0 && editRight == 1' class="uil uil-edit-alt"
                           style="font-size: 19px;cursor:pointer;color:#F3766A;" @click="openHolidaysModal(data.item)"></i>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage4" :per-page="perPage4" :total-rows="rows4"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <!-- Table Category end-->
            <b-tab title="Add Payment Gateway Charges" title-item-class="mb-2">
              <b-card-text>

                <h6> Add Payment Gateway Charges in %</h6>
                <div class="row">
                  <div class="mt-3 col-md-6">
                    <div class="form-group">
                      <input v-model="payment_gateway_charge" class="form-control" min="0.0" step="0.01" type="number">
                    </div>

                  </div>
                  <div class="mt-3 col-md-6">
                    <button class="btn btn-themeYellow" @click="addPaymentGatewayCharge()"> Submit
                      <i class="uil uil-plus"></i></button>
                  </div>

                </div>

              </b-card-text>
              <!-- Category add end here -->
            </b-tab>

            <b-tab title="View Payment Gateway Charges" title-item-class="mb-2" @click="getPaymentGatewayCharges()">
              <b-card-text>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <button class="btn btn-themeBrown"
                            type="button"
                            @click="downloadSample(6)"
                    >
                      Excel
                    </button>
                  </div>
                  <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show &nbsp;&nbsp;
                        <b-form-select v-model="perPage6" :options="pageOptions6" size="sm" style="margin-left:5px;
                        margin-right:5px"></b-form-select>&nbsp;&nbsp;&nbsp; entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-4 row" style="margin-bottom:15px;">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter6" class="form-control form-control-sm ms-2"
                                      placeholder="Search..." type="search"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                  <div class="table-responsive mb-0">
                    <b-table :current-page="currentPage6"
                             :fields="fields6"
                             :filter="filter6"
                             :filter-included-fields="filterOn6"
                             :items="tableData6"
                             :per-page="perPage6"
                             bordered
                             fixed-header
                             hover
                             outlined
                             responsive
                             sticky-header="500px"
                             striped
                             @filtered="onFiltered4">
                      <template #cell(status)="data">
                        <span v-if='data.item.status == "Active"' class="text-success font-size-16"
                           >{{data.item.status}}</span>
                        <span v-else class="text-danger font-size-16"
                        >{{data.item.status}}</span>
                      </template>
                    </b-table>

                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage6" :per-page="perPage6" :total-rows="rows6"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="View Settlement Periods" title-item-class="mb-2" @click="getSettlementCycleTypes()">
              <b-card-text>
                <div class="row mt-4">
                  <div class="mt-3 col-md-6">
                    <label>Select Settlement Cycle Type *</label>
                    <multiselect
                        v-model="settlementCycle"
                        :options="settlementCycles"
                        label="settlement_type"
                        track-by="id"
                        @input="getSettlementPeriods()"></multiselect>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-4"><strong>Settlement Period Name</strong></div>
                  <div class="col-md-4"><strong>Settlement Period <br>Start Date Per Month</strong></div>
                  <div class="col-md-4"><strong>Settlement Period <br>End Date Per Month</strong></div>
                </div>

                <div v-for="period in settlementPeriods" :key="period.id" class="row mt-2">
                <div
                    class="col-md-4"><input :value="period.period_name" class="form-control" readonly type="text"></div>
                <div class="col-md-4"><input :value="period.start" class="form-control" readonly type="text"></div>
                <div class="col-md-4"><input :value="period.end" class="form-control" readonly type="text"></div>
                </div>
              </b-card-text>
            </b-tab>
            <!-- modal for Category edit  -->
            <b-modal id="modal-lg-editholidays" v-model="sectionModal4" title="Edit Holiday" @ok="addCorporateHoliday(1)">
              <div class="row">
                <div class="mt-3 col-md-12">
                  <label>Holiday Title</label>
                  <input v-model="corporatesData.holidayTitle" class="form-control" placeholder="Eg: Diwali" type="text">
                  <!-- {{corporatesData.holidayTitle}} -->
                </div>
                <div class="mt-3 col-md-12">
                  <label> Holiday Date</label>
                  <input v-model="corporatesData.holidayDate" class="form-control" type="date">
                </div>
                <p style="color:red;">{{errorMsg}}</p>
                <!-- <div class="mt-3 col-md-6">
                    <label>Select Corporate*</label>
                    <multiselect v-model="corporatesData.corporateName" :options="corporates" track-by="corporateID" @input="getCorporateList(data.item);" label="corporateName"></multiselect>
                </div>
                <div class="mt-5 col-md-6">
                    <button class="btn btn-themeYellow" v-b-modal.addHolidayModal> Add Holiday <i class="uil uil-plus"></i></button>
                </div> -->
              </div>
            </b-modal>
            <!-- modal for Category edit end here -->
            <!-- Corporate Holidays -->

          </b-tabs>

        </div>

      </div>
    </div>

    <!-- Holiday MOdal  -->
    <b-modal id="addHolidayModal" title="Add Holiday" title-class="font-18">

      <div class="mt-3 col-md-12">
        <label>Holiday Title</label>
        <input v-model="holidayTitle" class="form-control" placeholder="Eg: Diwali" type="text">
      </div>
      <div class="mt-3 col-md-12">
        <label> Holiday Date</label>
        <input v-model="holidayDate" class="form-control" type="date">
      </div>
      <!-- <p style="color:red;">{{errorMsg}}</p> -->

      <template #modal-footer style="border-top:none !important;">
        <div class="w-100">

          <b-button size="sm" style="float:right;" variant="primary" @click="addCorporateHoliday(0)">
            Submit
          </b-button>
        </div>
      </template>

    </b-modal>
    <!--  end of holiday modal -->

  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {authHeader} from '../../../helpers/fakebackend/auth-header';
import {required} from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
    page: {
        title: "Miscellaneous",
        meta: [{
            name: "description",
            content: appConfig.description,
        }, ],
    },
    components: {
        Multiselect,
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Miscellaneous",
            items: [
                {
                    text: "Miscellaneous",
                    href: "/",
                },
                {
                    text: "Add",
                    active: true,
                },
            ],
            isEdit: false,
            excelFields: {},
            alertMsg: '',
            // alertMsg :"Updated Successfully!",
            cuisineData: '',
            locationData: '',
            categoryData: '',
            corporatesData: '',
            statesData: '',
            status: '',
            submitted: false,
            showDismissibleAlert: false,
            loading: false,
            specialityName: "",
            state: "",
            country: "India",
            city: "",
            branchName: "",
            categoryName: "",
            sectionModal1: false,
            sectionModal2: false,
            sectionModal3: false,
            sectionModal4: false,
            sectionModal5: false,
            sectionModal6: false,
            // datatable for speciality //////
            tableData: [],
            totalRows: 2,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100,"All"],
            filter: null,
            filterOn: [],
            sortBy: "created",
            sortDesc: false,
            fields: [
                {
                    key: "Action",
                    sortable: false,
                },
                {
                    key: "cuisineName",
                    sortable: true,
                },
                {
                    key: "created",
                    sortable: true,
                },
            ],
            ////// end of sepeciality datatable /////

            ////// datatable for locations //////
            locationTableData: [],
            totalRows2: 1,
            currentPage2: 1,
            perPage2: 10,
            pageOptions2: [10, 25, 50, 100,"All"],
            filter2: null,
            filterOn2: [],
            sortBy2: "created",
            sortDesc2: false,
            fields2: [
                {
                    key: "Action",
                    sortable: false,
                },
                {
                    key: "branchName",
                    sortable: true,
                },
                {
                    key: "city",
                    sortable: true,
                },
                {
                    key: "state",
                    sortable: true,
                },
                {
                    key: "created",
                    sortable: true,
                },
            ],
            ////// end of locations datatable /////

            ////// datatable for category //////
            categoryDatatable: [],
            totalRows3: 1,
            currentPage3: 1,
            perPage3: 10,
            pageOptions3: [10, 25, 50, 100,'All'],
            filter3: null,
            filterOn3: [],
            sortBy3: "created",
            sortDesc3: false,
            fields3: [
                {
                    key: "Action",
                    sortable: false,
                },
                {
                    key: "categoryName",
                    sortable: true,
                },
                {
                    key: "created",
                    sortable: true,
                },
            ],
            ////// end of category datatable /////
            ////////// holidays section /////////
            tableData4: [],
            corporates: [],
            corporateID: "",
            shortCode: "",
            holidayDate: "",
            holidayTitle: "",
            errorMsg: "",
            totalRows4: 1,
            currentPage4: 1,
            perPage4: 10,
            pageOptions4: [10, 25, 50, 100,'All'],
            filter4: null,
            filterOn4: [],
            sortBy4: "holidayDate",
            sortDesc4: false,
            fields4: [
                {
                    key: "Action",
                    sortable: false,
                },
                {
                    key: "holidayTitle",
                    sortable: true,
                },
                {
                    key: "corporateName",
                    sortable: true,
                },            
                {
                    key: "holidayDate",
                    label: 'Created',
                    sortable: true,
                },                
            ],
          totalRows6: 1,
          currentPage6: 1,
          perPage6: 10,
          pageOptions6: [10, 25, 50, 100,'All'],
          filter6: null,
          filterOn6: [],
          tableData6: [],
          settlementCycles: [],
          settlementPeriods: [],
          settlementCycle:"",
          sortBy6: "holidayDate",
          sortDesc6: false,
          fields6: [
            {
              key: "status",
              label: "Status",
              sortable: false,
            },
            {
              key: "charge",
              label: "Payment Gateway Charges in %",
              sortable: true,
            },
            {
              key: "created_at",
              sortable: true,
              label:"Created On"
            },
            {
              key: "created_by",
              label: 'Created By',
              sortable: true,
            },
          ],
            ///////////// end of holidays section////

            ////////// states section /////////
            // corporates: [],
            // corporateID: "",
            // holidayDate: "",
            // holidayTitle: "",
            // errorMsg: "",
            // state: "",
            // country: "India",
            statesTableData: [],
            cloudKitchGstNo: "",
            cloudKitchBillingAddress: "",
            totalRows5: 1,
            currentPage5: 1,
            perPage5: 10,
            pageOptions5: [10, 25, 50, 100,'All'],
            filter5: null,
            filterOn5: [],
            sortBy5: "created",
            sortDesc5: false,
            fields5: [
                {
                    key: "Action",
                    sortable: false,
                },
                {
                    key: "state",
                    sortable: true,
                },
                {
                    key: "country",
                    sortable: true,
                },
                {
                    key: "shortCode",
                    sortable: true,
                },
                {
                    key: "cloudKitchGstNo",
                    sortable: true,
                },
                {
                    key: "cloudKitchBillingAddress",
                    sortable: true,
                },
                {
                    key: "created",
                    sortable: true,
                },
            ],
            ///////////// end of states section////
            path: "",
            editRight: "",
            deleteRight: "",
          payment_gateway_charge:0.0,
        };
    },
  validations: {

    categoryName: {
      required,
    },
  },
    methods: {
        clearFilter(){
            this.corporateID=""; 
            this.readAllCorpHolidays();  
        },
      
        applyFilter(){
            this.readAllCorpHolidays();
        },

        //excel code
        downloadSample(flag) {
            var exportData='';
            var page='';
            switch(flag){
                case 1: //speciality
                exportData=this.tableData;
                page="Speacility"
                break;
                case 2: //Location
                exportData=this.locationTableData;
                page="Location"
                break;
                case 3: //states
                exportData=this.statesTableData;
                page="States"
                break;
                case 4: //categories
                exportData=this.categoryDatatable;
                page="Categories"
                break;
                case 5: //corprate Holiday
                exportData=this.tableData4;
                page="Holidays"
                break;
              case 6:
                exportData=this.tableData6;
                page="Payment Gateway Charges"
                break;
            }
            this.axios.post(this.$loggedRole+"/exportExcel", {
                "exportData": exportData,
                "page": page,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            const url = URL.createObjectURL(
                new Blob([response.data], {
                type: "application/vnd.ms-excel",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Miscellaneous.xlsx");
            document.body.appendChild(link);
            link.click();
            });
        },
        //excel code end

        checkUserRights() {
            this.path = this.$route.path;
            this.axios.post(this.$loggedRole+"/checkUserRights", {
                empID: this.$storageData.profile.pid,
                empTypeID: this.$storageData.profile.empTypeID,
                path: this.path,
            })
            .then((result) => {
              if(result.data.data.length > 0)
              {
                this.editRight = result.data.data[0].isEdit;
                this.deleteRight = result.data.data[0].isDelete;
              }

            });
        },
      addPaymentGatewayCharge(){
        this.axios
            .post(this.$loggedRole+"/payment-gateway-charges/add-new-charge", {
              charge: this.payment_gateway_charge,
              employee_id: this.$storageData.profile.pid,
            })
            .then((response) => {
              this.payment_gateway_charge=0.0;
                alert(response.data.message);
            }).catch((error)=>{
          alert(error.response.data.message);
        });
      },
      getPaymentGatewayCharges(){
        this.axios
            .get(this.$loggedRole+"/payment-gateway-charges/get-all-charges")
            .then((response) => {
              if(response.data.status==200){
                this.tableData6 = response.data.data;
              }
              else{
                alert(response.data.message);
              }

            })
            .catch((error)=>{
              alert(error.response.data.message);
            });
      },
      getSettlementCycleTypes(){
        this.axios
            .get(this.$loggedRole+"/get-settlement-cycle-types")
            .then((response) => {
              if(response.data.status==200){
                this.settlementCycles = response.data.data;
              }
              else{
                alert(response.data.message);
              }

            })
            .catch((error)=>{
              alert(error.response.data.message);
            });
      },
      getSettlementPeriods(){
          if(this.settlementCycle.length==0)
          {
            alert('Please select settle cycle!');
          }
          else {
            this.axios
                .get(this.$loggedRole+"/get-settlement-periods/"+this.settlementCycle.id)
                .then((response) => {
                  if (response.data.status == 200) {
                    this.settlementPeriods = response.data.data;
                  } else {
                    alert(response.data.message);
                  }

                })
                .catch((error) => {
                  alert(error.response.data.message);
                });
          }
      },
        ///// specaility datatable methods  /////////
        getSpecialities() {
            this.readAllSpecialities();
        },

        readAllSpecialities() {
            this.axios.post(this.$loggedRole+"/viewAllSpecialities",{
              'restID': 0,
              'empTypeID': this.$storageData.profile.empTypeID
            })
        .then((result) => {
                    this.excelFields = {};
                    this.tableData = result.data.data;
                    this.fields.forEach(element => {
                    // console.log(element.key);
                    var key = element.key;
                    // var tempObj = {key: key};
                    this.excelFields[''+key+''] = key;
                    // console.log(tempObj);
                    // tempExcelFields = {tempExcelFields, tempObj};
                });
            });
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        ///// specaility datatable methods end  /////////

        ///// branches datatable methods  /////////
        getLocations() {
            this.readAllLocations();
        },

        readAllLocations() {
            this.axios.post(this.$loggedRole+"/viewAllLocations")
                .then((result) => {
                    this.excelFields = {};
                    this.locationTableData = result.data.data;
                    this.fields2.forEach(element => {
                    // console.log(element.key);
                    var key = element.key;
                    // var tempObj = {key: key};
                    this.excelFields[''+key+''] = key;
                    // console.log(tempObj);
                    // tempExcelFields = {tempExcelFields, tempObj};
                });
            });
        },

        onFiltered2(filteredItems2) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows2 = filteredItems2.length;
            this.currentPage2 = 1;
        },

        ///// branches datatable methods end  /////////

        ///// states datatable methods  /////////
        getStates() {
            this.readAllStates();
        },

        readAllStates() {
            this.axios.post(this.$loggedRole+"/viewAllStates")
            .then((result) => {
                this.excelFields = {};
                this.statesTableData = result.data.data;
                this.fields5.forEach(element => {
                    // console.log(element.key);
                    var key = element.key;
                    // var tempObj = {key: key};
                    this.excelFields[''+key+''] = key;
                    // console.log(tempObj);
                    // tempExcelFields = {tempExcelFields, tempObj};
                });
            });
        },

        onFiltered5(filteredItems5) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows5 = filteredItems5.length;
            this.currentPage5 = 1;
        },
        ///// states datatable methods end /////////

        ///// category datatable methods  /////////
        getCategories() {
            this.readAllCategories();
        },

        readAllCategories() {
            this.axios
                .post(this.$loggedRole+"/getCategoryList", {
                    'restID': 0,
                    'empTypeID': this.$storageData.profile.empTypeID
                })
                .then((result) => {
                    this.excelFields = {};
                    this.categoryDatatable = result.data.data;
                    this.fields3.forEach(element => {
                    // console.log(element.key);
                    var key = element.key;
                    // var tempObj = {key: key};
                    this.excelFields[''+key+''] = key;
                    // console.log(tempObj);
                    // tempExcelFields = {tempExcelFields, tempObj};
                });
            });
        },

        onFiltered3(filteredItems3) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows3 = filteredItems3.length;
            this.currentPage3 = 1;
        },

        ///// category datatable methods end  /////////

        /////////// methods for holidays section /////////
        getHolidaysData() {
            this.getCorporateList();
            // this.readAllCorpHolidays();
        },

        getCorporateList() {
            this.axios.get(this.$loggedRole+"/getCorporateList")
            .then((result) => {
                this.corporates = result.data.data;
                //console.log();
            });
        },

        readAllCorpHolidays() {
            this.axios.post(this.$loggedRole+"/viewAllCorporateHolidays", {
                    'corporateID': this.corporateID.corporateID
                })
                .then((result) => {
                    this.excelFields = {};
                    this.tableData4 = result.data.data;
                    this.fields4.forEach(element => {
                    // console.log(element.key);
                    var key = element.key;
                    // var tempObj = {key: key};
                    this.excelFields[''+key+''] = key;
                    // console.log(tempObj);
                    // tempExcelFields = {tempExcelFields, tempObj};
                });
            });
        },

        onFiltered4(filteredItems4) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows4 = filteredItems4.length;
            this.currentPage4 = 1;
        },

        addCorporateHoliday(editFlag) {             
            if (this.corporatesData.corporateID == "") {
                this.errorMsg = "Please Select Corporate";
                return;
            }
            if (this.corporatesData.holidayDate == "" || this.corporatesData.holidayTitle == "") {
                this.errorMsg = "Required Fields are Missing";
                return;
            }
            let formData = new FormData();
            let apiName = this.$loggedRole+"/addCorporateHoliday";

            if(editFlag==1){
                this.isEdit=true;
            }else{
                this.isEdit=false;
            }
            if (this.isEdit == true) {
                // alert is in condition
                apiName = this.$loggedRole+"/updateCorporateHoliday";
                this.alertMsg = "Details Updated Successfully !";
                //console.log('holidayTitle')
                formData.append('holidayTitle', this.corporatesData.holidayTitle);
                formData.append('holidayDate', this.corporatesData.holidayDate);
                 formData.append('holidayID', this.corporatesData.holidayID);
                formData.append('createdBy', this.$storageData.profile.pid);
                formData.append('loginTypeID', this.$storageData.login_type);
                this.$root.$emit("bv::hide::modal", "editholidays");
            } else {
                this.$root.$emit("bv::hide::modal", "addHolidayModal");
                this.alertMsg = "Details Added Successfully !";
                formData.append('holidayTitle', this.holidayTitle);
                formData.append('holidayDate', this.holidayDate);
                formData.append('corporateID', this.corporateID.corporateID);
                formData.append('createdBy', this.$storageData.profile.pid);
                formData.append('loginTypeID', this.$storageData.login_type);
            }
            this.axios.post(apiName, formData, {
                headers: authHeader()
            })
            .then((result) => {
                //  this.status = result.data.data;
                this.showDismissibleAlert = true;
                this.tableData4 = result.data.data;
                this.errorMsg = "";
                this.getHolidaysData();

                setTimeout(() => {
                    this.showDismissibleAlert = false;
                }, 2000);

            })
            .catch((error) => {
                this.loading = false;
                alert(error.response.data.data);
            });
        },
        
        /////////////// end of holiday section ////////////////

        // state api call here
        addStates(editFlag) {
            // console.log("addStates");
            let formData = new FormData();
            let apiName = this.$loggedRole+"/addStates";

            if(editFlag==1){
                this.isEdit=true;
            }else{
                this.isEdit=false;
            }

            if (this.isEdit == true) {
                apiName = this.$loggedRole+"/updateStates";
                this.alertMsg = "Details Updated Successfully !";
                //console.log('state')
                formData.append('state', this.statesData.state);
                formData.append('country', this.statesData.country);
                formData.append('shortCode', this.statesData.shortCode);
                formData.append('cloudKitchGstNo', this.statesData.cloudKitchGstNo);
                formData.append('cloudKitchBillingAddress', this.statesData.cloudKitchBillingAddress);
                formData.append('stateID', this.statesData.stateID);
                formData.append('createdBy', this.$storageData.profile.pid);
                formData.append('loginTypeID', this.$storageData.login_type);
               this.$root.$emit("bv::hide::modal", "modal-lg-editstates");
            } else {
                this.$root.$emit("bv::hide::modal", "modal-lg-addstates");
                this.alertMsg = "Details Added Successfully !";
                //console.log('state')
                formData.append('state', this.state);
                formData.append('country', this.country);
                formData.append('shortCode', this.shortCode);
                formData.append('cloudKitchGstNo', this.cloudKitchGstNo);
                formData.append('cloudKitchBillingAddress', this.cloudKitchBillingAddress);
                //formData.append('stateID', this.cuisineData.stateID);
                formData.append('createdBy', this.$storageData.profile.pid);
                formData.append('loginTypeID', this.$storageData.login_type);
                //  this.$router.go()	
            }
            this.$root.$emit("bv::hide::modal", "addHolidayModal");
            this.axios.post(apiName, formData, {
                headers: authHeader()
            })
            .then((result) => {
                //  this.status = result.data.data;
                this.showDismissibleAlert = true;
                this.status = result.data.data;
                this.errorMsg = "";
                this.state = "";
                this.country = "";
                this.shortCode = "";
                this.cloudKitchGstNo = "";
                this.cloudKitchBillingAddress = "";
                setTimeout(() => {
                    this.showDismissibleAlert = false;
                }, 2000);
                this.readAllStates();
            })
            .catch((error) => {
                this.loading = false;
                alert(error.response.data.data);
            });
        },

        // state api end here
        ///function for seciality
        openSecalityModal(data) {
            this.$root.$emit("bv::show::modal", "modal-lg-editspecility");
            //   this.selectedPrincipleID = sectionId;
            this.getCuisineById(data.cuisineID);
            // this.cuisineData = data;
            // console.log(this.cuisineData);
            this.isEdit = true;
        },

        updateSpl() {
            alert();
        },

        getCuisineById(cuisineID) {
            this.cuisineData="";
            this.axios
            .post(this.$loggedRole+"/getCuisineById", {
                'cuisineID': cuisineID,
            })
            .then((result) => {
                this.cuisineData = result.data.data;
            });
        },

        ///function for seciality end

        ///function for Location
        openLocationModal(data) {
            this.$root.$emit("bv::show::modal", "modal-lg-editlocation");
            // this.selectedPrincipleID = sectionId;
            this.getLocationById(data.branchID);
           // this.locationData = data;
            // console.log(this.locationData);
            this.isEdit = true;
        },

        getLocationById(branchID) {
            this.locationData="";
            this.axios.post(this.$loggedRole+"/getLocationById", {
                'branchID': branchID,
            })
            .then((result) => {
                this.locationData = result.data.data[0];
            });
        },

        ///function for Location end

       ///function for category
        openCategoryModal(data) {
            this.$root.$emit("bv::show::modal", "modal-lg-editcategory");
            //   this.selectedPrincipleID = sectionId;
            this.getCategoryById(data.categoryID);
            // this.categoryData = data;
            // console.log(this.categoryData);
            this.isEdit = true;
        },

        getCategoryById(categoryID) {
            this.categoryData="";
            this.axios.post(this.$loggedRole+"/getCategoryById", {
                'categoryID': categoryID,
            })
            .then((result) => {
                this.categoryData = result.data.data;
            });
        },
        ///function for category end

       ///function for corpholidays
        openHolidaysModal(data) {
            this.$root.$emit("bv::show::modal", "modal-lg-editholidays");
            // this.selectedPrincipleID = sectionId;
            this.getHolidaysById(data.holidayID);
            // this.corporatesData = data;
            // console.log(this.corporatesData);
            this.isEdit = true;
        },

        getHolidaysById(holidayID) {
            this.corporatesData="";
            //console.log(this.corporatesData);
            this.axios.post(this.$loggedRole+"/getHolidaysById", {
                'holidayID': holidayID,
            })
            .then((result) => {
                this.corporatesData = result.data.data;
            });
        },

        ///function for corpholidays end
        ///function for states edit
        openStatesModal(data) {
            this.$root.$emit("bv::show::modal", "modal-lg-editstates");
            // this.selectedPrincipleID = sectionId;
            this.getStateById(data.stateID);
            // this.statesData = data;
            // console.log(this.statesData);
            this.isEdit = true;
        },

        getStateById(stateID) {
            this.statesData="";
            this.axios.post(this.$loggedRole+"/getStateById", {
                'stateID': stateID,
            })
            .then((result) => {
                this.statesData = result.data.data;
            });
        },
        ///function for states edit end
        openStatesAddModal() {
            this.$root.$emit("bv::show::modal", "modal-lg-addstates");
            // this.selectedPrincipleID = sectionId;
            // this.getHolidaysById(holidayID);
            // this.statesData = data;
            // console.log(this.statesData);
            //this.isEdit=true;
        },

        submitForm(flag,editFlag) {
            //flag: 1=>speciality

            if(editFlag==1){
                this.isEdit=true;
            }else{
                this.isEdit=false;
            }
            
            this.submitted = true;
            // this.$v.$touch();
            // if (this.$v.$invalid) {
            //     return;
            // } else {

                // let routerPush = "restaurantBranchForm";
                console.log(this.isEdit);
                let formData = new FormData();

                let apiName = this.$loggedRole+"/addMiscellaneousForm";

                if (this.isEdit == true) {
                    

                    // alert is in condition
                    apiName = this.$loggedRole+"/updateMiscellaneousForm";
                    this.alertMsg = "Updated Successfully!";
                    
                    switch (flag) {
                        case 1:
                            formData.append('specialityName', this.cuisineData.cuisineName);
                            formData.append('cuisineID', this.cuisineData.cuisineID);
                            break;
                        case 2:
                            formData.append('branchName', this.locationData.branchName);
                            if(this.isEdit==true){
                                formData.append('state', this.locationData.stateOb.state);
                            }else{
                                formData.append('state', this.state.state);
                            }
                            formData.append('city', this.locationData.city);
                            formData.append('country', this.locationData.country);
                            formData.append('branchID', this.locationData.branchID);
                            break;
                        case 3:
                            formData.append('categoryName', this.categoryData.categoryName);
                            formData.append('categoryID', this.categoryData.categoryID);
                            break;
                        default:
                            // code block
                    }
                    formData.append('flag', flag);
                    formData.append('createdBy', this.$storageData.profile.pid);
                    formData.append('loginTypeID', this.$storageData.login_type);
                    // routerPush = "restaurantTable";
                } else {
                    // let formData = new FormData();
                    this.alertMsg = 'Details Added Successfully!';
                    switch (flag) {
                        case 1:
                            formData.append('specialityName', this.specialityName);
                            break;
                        case 2:
                            formData.append('branchName', this.branchName);
                            formData.append('state', this.state.state);
                            formData.append('city', this.city);
                            formData.append('country', this.country);
                            break;
                        case 3:
                            formData.append('categoryName', this.categoryName);
                            break;
                        default:
                            // code block
                    }
                    formData.append('flag', flag);
                    formData.append('createdBy', this.$storageData.profile.pid);
                    formData.append('loginTypeID', this.$storageData.login_type);

                }
                this.axios.post(apiName, formData, {
                        headers: authHeader()
                    })
                    .then((result) => {
                        this.status = result.data.data;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                        this.specialityName = "";
                        this.branchName = "";
                        this.city = "";
                        this.state = "";
                        this.categoryName = "",

                            setTimeout(() => {
                                this.showDismissibleAlert = false;
                            }, 2000);
                            switch (flag) {
                        case 1:
                        this.readAllSpecialities();
                        break;
                        case 2:
                        this.readAllLocations();
                        break;
                        case 3:
                        this.readAllCategories();
                        break;
                         
                 }

                    })
                    .catch((error) => {
                        this.loading = false;
                        alert(error.response.data.data);

                    });
            // }
        },
    },

    computed: {
        /**
         * Total no. of records
         */
        rows() {
        return this.tableData.length;
        },

        rows2() {
        return this.locationTableData.length;
        },

        rows3() {
        return this.categoryDatatable.length;
        },

        rows4() {
        return this.tableData4.length;
        },

        rows5() {
        return this.statesTableData.length;
        },
      rows6(){
          return this.tableData6.length;
      },
    },

    mounted() {
        this.totalRows4 = this.items.length;
        this.totalRows2 = this.items.length;
        this.totalRows3 = this.items.length;
        this.totalRows5 = this.items.length;
        this.totalRows6 = this.items.length;
        this.getCorporateList()
        this.getLocations()
        this.getStates()
        this.totalRows = this.items.length;
        this.checkUserRights();
    },

    middleware: "authentication",
};
</script>

<style scoped>
.alertMsg {
    position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>
